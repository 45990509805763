@if(item.hasOwnProperty(field)) {
  @if(type=='text'){
    {{ item[field] }}
  }@else if (type=="textWithCount") {
    {{ item[field]['text'] }}
    @if(item[field]['count']){
      <br>
      <a class="a-link" href="javascript:void">View all ({{item[field]['count']}}) Items</a>
    }
  }
  @else if(type=='datetime'){
    {{item[field] | dateTimeFormat }}
  } @else if(type=='para') {
    <app-read-more [text]="item[field]" [maxLength]="limit"></app-read-more>
  } @else if (type=='colorLabel') {
       @if(item[field].title){
        <label class="{{ getClass(item[field].color_code) }}">{{ item[field].title }}</label>
       }
  } @else if (type=='user' && item[field]) {
    @if(item[field].first_name){
      <app-user-image
        [type]="'small'"
        [initial]="item[field]['initial']"
        [firstName]="item[field].first_name"
        [LastName]="item[field].last_name">
      </app-user-image>
    }
  }
  @else if(type=='multipleUsers' && item[field]) {
    <div class="user-widget d-flex">
    @for(user of item[field]; track user; let index = $index) {
      @if (index < (userCount?userCount:2)) {
        <app-user-image
          [imageUrl]="user.profile_image_path"
          [initial]="user['initial']"
          [color]="user.color"
          [type]="'small'">
        </app-user-image>
      }
    }
    <a  class="a-link ps-2  dropdown-menu-start cursor-pointer"  data-bs-toggle="dropdown" >View All</a>

    <ul class="dropdown-menu">
      @for(user of item[field]; track user) {
      <li>
        <a class="dropdown-item d-flex align-items-center">
          <app-user-image
            [imageUrl]="user.profile_image_path"
            [firstName]="user.first_name"
            [LastName]="user.last_name"
            [initial]="user.initial"
            [color]="user.color"
            [type]="'small'">
          </app-user-image>
          <div class="ms-1">{{user.name}}
          </div>
        </a>
      </li>
      }
    </ul>
  </div>
  } @else if (type=='document') {
    <app-label-display
      [label]="''"
      [type]="'document'"
      [document]="item.document">
    </app-label-display>
  } @else if (type=='documents') {
    <app-label-display
      [label]="''"
      [type]="'documents'"
      [documents]="item[field]">
    </app-label-display>

  } @else {
    {{ item[field] }}
  }
}
