<div class="table-responsive ">
  <table class="table table-bordered theme-table mb-0 mt-0">
    <thead>
      <tr>
        @if(enableSelection){
          <th style="width: 5%">
              <input type="checkbox" (change)="selectAll($event)">
          </th>
        }
        <th style="width: 5%">#</th>
        @for(col of columns; track col.field){
          <th
            [class.sortable-header]="col.sortField"
            (click)="col.sortField ? requestSort(col) : null"
            [style.min-width.px]="col.size">
            @if(col.type=='primaryUnit'){
              Division(TBD)
            }@else {
              {{ col.header | translate }}
            }

            @if(col.sortField){
              <app-sort-icon
                [sortField]="sortField"
                [currentField]="getCurrentField(col)"
                [sortOrder]="sortOrder">
              </app-sort-icon>
            }
          </th>
        }
        @if(customColumn){
          <th style="width: 5%">{{customColumnTitle}}</th>
        }

        @if(actionConfig){
          <th style="width: 5%">Action</th>
        }
      </tr>
    </thead>
    <tbody>
      @if (data && data.length > 0) {
        @for (item of data; track item.id) {
          <tr [style.cursor]="rowUrl || isDetailsModal ? 'pointer' : 'default'"  [class.disabled-row]="item.is_disabled">
            @if(enableSelection){
              <td>
                <input type="checkbox" [(ngModel)]="item.is_checked">
              </td>
            }
            <td (click)="onRowClick(item)">
              <app-serial-number
                [index]="$index"
                [isPaginationEnabled]="isPaginationEnabled"
                [currentPage]="isPaginationEnabled ? paginationConfig.currentPage : 1"
                [pageSize]="isPaginationEnabled ? paginationConfig.pageSize : 0"
                [rowUrl]="rowUrl">
              </app-serial-number>
            </td>
            @for (col of columns; track col.header) {
              @if(isRawSpan && isExist(col.field,item)){
                <td
                    (click)="onRowClick(item)"
                    [attr.rowspan]="col.isRawSpan && item.rowspan > 1 ? item.rowspan : null">
                  <app-data-cell
                    [type]="col.type"
                    [field] ="col.field"
                    [userCount]="col.userCount"
                    [item]="item"
                    [rowUrl]="rowUrl"
                    [count]="item.statement_count"
                    [limit]="col.limit">
                  </app-data-cell>
                </td>
              }@else if(!isRawSpan) {
                <td
                  (click)="onRowClick(item)"
                  [attr.rowspan]="col.isRawSpan && item.rowspan > 1 ? item.rowspan : null">
                  <app-data-cell
                    [type]="col.type"
                    [field] ="col.field"
                    [userCount]="col.userCount"
                    [item]="item"
                    [rowUrl]="rowUrl"
                    [count]="item.statement_count"
                    [limit]="col.limit">
                  </app-data-cell>
               </td>
              }
            }
              @if(customColumn){
                <td>
                  <button class="btn btn-primary btn-theme" type="button"  [disabled]="item.control_test_id == null ? false: true" (click)="performClickAction(item)">
                    {{item.control_test_id == null ? 'Test': 'Tested'}}
                  </button>
                </td>
              }


              @if(actionConfig){
                <td>
                  <app-table-item-action-dropdown
                    [item]="item"
                    [config]="actionConfig"
                    (editRequest)="openEditModal(item)"
                    (activateRequest)="requestActivateItem(item.id)"
                    (deactivateRequest)="requestDeactivateItem(item.id)"
                    (downloadRequest)="requestDownloadItem(item)"
                    (deleteRequest)="requestDeleteItem(item.id)"
                    (applicableRequest)="requestApplicableItem(item)"
                    (notApplicableRequest)="requestNotApplicableItem(item)">
                  </app-table-item-action-dropdown>
                </td>
              }
          </tr>
        }
      } @else if(!isLoading) {
          <tr>
            <td [attr.colspan]="columns.length + 2" class="text-center">
              <app-no-records-found></app-no-records-found>
            </td>
          </tr>
        }
    </tbody>
  </table>
</div>
@if (isPaginationEnabled && data && data.length > 0) {
  <app-pagination
    [config]="paginationConfig"
    (pageChangeRequest)="requestPageChange()">
  </app-pagination>
}
@if(enableSelection){
  <div class="col-md-12 ">
    <app-cancel-button (cancelRequest)="requestToCloseSelectionModal()"></app-cancel-button>
    <app-button [label]="'Add'"
      [isLoading]="isAdding"
      [loadingLabel]="'Adding'"
      (clickRequest)="requestToSelectedItemAction()">
    </app-button>
  </div>
}
